<template>
    <div class="nav-wrapper">
        <div v-for="(item,index) in nav" :key="index">
            <div>
                <div class="menu-item" :class="{'is-active':item.path === navPath}">
                    <div @click="navTab(item.path)">
                        <img :src="item.icon" alt="" v-if="item.path === navPath">
                        <img :src="item.selectedIcon" alt="" v-else>
                        {{ item.name }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "nav-modular",
    data() {
        return {
        }
    },
    props: {
        navPath: {
            type: String
        }
    },
    created() {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        nav(){
            if(this.user.role_id === 1){
                return [{
                    id: 7,
                    name: '公司列表',
                    path: '/admin/orgList',
                    icon: require('../assets/img/icon_org.png'),
                    selectedIcon: require('../assets/img/icon_orgs.png'),
                },{
                  id: 9,
                  name: '集团列表',
                  path: '/admin/groupList',
                  icon: require('../assets/img/icon_org.png'),
                  selectedIcon: require('../assets/img/icon_orgs.png'),
                },{
                    id: 8,
                    name: '管理员列表',
                    path: '/admin/subList',
                    icon: require('../assets/img/icon_expert.png'),
                    selectedIcon: require('../assets/img/icon_experts.png'),
                }]
            }else{
                return [{
                    id: 1,
                    name: '待办事项',
                    path: '/admin/needList',
                    icon: require('../assets/img/icon_score.png'),
                    selectedIcon: require('../assets/img/icon_scores.png'),
                },
                    {
                        id: 2,
                        name: '员工信息',
                        path: '/admin/staffList',
                        icon: require('../assets/img/icon_expert.png'),
                        selectedIcon: require('../assets/img/icon_experts.png'),
                    },
                    {
                        id: 3,
                        name: '员工入职',
                        path: '/admin/expertList',
                        icon: require('../assets/img/icon_expert.png'),
                        selectedIcon: require('../assets/img/icon_experts.png'),
                    }, {
                        id: 4,
                        name: '员工离职',
                        path: '/admin/resignationList',
                        icon: require('../assets/img/icon_expert.png'),
                        selectedIcon: require('../assets/img/icon_experts.png'),
                    }, {
                        id: 5,
                        name: '公司信息',
                        path: '/admin/orgInfo',
                        icon: require('../assets/img/icon_org.png'),
                        selectedIcon: require('../assets/img/icon_orgs.png'),
                    }, {
                        id: 6,
                        name: '模板管理',
                        path: '/admin/templateList',
                        icon: require('../assets/img/icon_score.png'),
                        selectedIcon: require('../assets/img/icon_scores.png'),
                    }]
            }
        }
    },
    components: {},
    methods: {
        navTab(path) {
            this.$router.push(path)
        }
    },
    filters: {},
    watch: {},
}
</script>

<style scoped lang="scss">
.nav-wrapper {
  position: absolute;
  top: 75px;
  left: 0;
  bottom: 0;
  width: 260px;
  padding: 50px 20px;
  background: #fff;
  overflow: auto;


  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
    background-color: #f2f9ff;
    margin-bottom: 20px;

    &.is-active {
      color: #fff;
      background-color: #368ad1;
      box-shadow: 0px 3px 8px 0px rgba(54, 138, 209, 0.32);
    }

    div {
      flex: 1;
      padding-left: 30px;
      height: 54px;
      line-height: 54px;

      img {
        width: 18px;
        margin-right: 8px;
      }
    }

    i {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-right: 30px;
    }

  }

  .menu-item-two {
    display: flex;
    align-items: center;
    height: 55px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;

    &.is-active {
      background: #368ad1;
    }

    div {
      flex: 1;
      padding-left: 60px;
      height: 54px;
      line-height: 54px;
    }

    a {
      display: block;
      flex: 1;
      font-size: 18px;
      color: #fff;
      padding-left: 60px;
      height: 54px;
      line-height: 54px;
    }
  }
}
</style>
