<template>
  <div class="title-warpper flex align-center justify-between">
    <div class="title-text flex align-center">
      <p v-html="pageTitle"></p>
    </div>
    <el-button size="medium" plain type="primary" v-if="isBack" @click="back">
      返回上一页
    </el-button>
  </div>
</template>

<script>
export default {
  name: "title-modular",
  data() {
    return {}
  },
  props:{
    pageTitle:{
      type:String,
      default:''
    },
    isBack:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.title-warpper{
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 15px;
  background: #fff;
  margin-top: 20px;
  padding: 0 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .title-text{
    color: #368ad1;
    font-size: 18px;
    letter-spacing: 1px;
    position: relative;
    font-weight: bold;
    &:before{
      position: absolute;
      bottom: -4px;
      left: 0;
      content: "";
      width: 100%;
      height: 4px;
      background: #368ad1;
      border-radius: 2px;
    }
  }
}
</style>
