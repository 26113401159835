<template>
  <el-container>
    <el-header>
      <headerModular/>
    </el-header>
    <el-container>
      <el-aside width="260px">
        <navModular class="left-nav" :navPath="navPath"/>
      </el-aside>
      <el-main class="margin-lr-sm">
        <titleModular :pageTitle="pageTitle" :isBack="isBack"/>
        <div class="main-warpper">
          <keep-alive include="staffList">
              <router-view @setIndex="getIndex(arguments)"/>
          </keep-alive>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import headerModular from "@/components/headerModular";
import navModular from "@/components/navModular";
import titleModular from "@/components/titleModular";

export default {
  name: "home",
  data() {
    return {
      navPath: '',
      pageTitle: '',
      isBack: false,
    }
  },
  created() {
  },
  components: {
    headerModular,
    navModular,
    titleModular,
  },
  methods: {
    getIndex(data) {
      this.navPath = data[0]
      this.pageTitle = data[1]
      this.isBack = data[2]
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.main-warpper {
  height: calc(100vh - 190px);
  padding-bottom: 20px;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
}
</style>
