<template>
  <div class="header-wrapper flex align-center">
    <div class="logo-box">善远HR管理系统 <span v-if="orgName"> - {{ orgName }}</span></div>
    <div class="flex-sub flex align-center justify-end user-info">
      <div class="flex align-center margin-right">
        <img src="../assets/img/avatar.png" alt="" class="margin-right-xs">
        <p class="font-size">{{ user.xingming }}</p>
      </div>

      <el-button
          v-if="user.role_id === 2 && orgList.length>1"
          type="warning" size="small" class="font-size" @click="tabOrg">切换公司
      </el-button>
      <el-button
          v-if="user.role_id === 2"
          type="warning" size="small" class="font-size" @click="setPass">修改密码
      </el-button>

      <el-button type="danger" size="small" class="font-size" @click="signOut">退出</el-button>
    </div>
    <!--新增修改管理员信息-->
    <el-drawer
        title="修改密码"
        :visible.sync="subDialog"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <div class="padding-lr">
        <el-form :model="subForm"
                 label-position="top"
                 :rules="rules" ref="subForm" class="add-form">
          <el-form-item label="旧密码" prop="oldPass">
            <el-input v-model="subForm.oldPass" placeholder="请填写旧密码"></el-input>
          </el-form-item>
          <el-form-item label="旧密码" prop="newPass">
            <el-input v-model="subForm.newPass" placeholder="请填写新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="surePass">
            <el-input v-model="subForm.surePass" placeholder="请确认密码"></el-input>
          </el-form-item>

          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('subForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>
  </div>
</template>

<script>

import {selfUpdatePassword} from "@/api/admin";

export default {
  name: "header-modular",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.subForm.surePass !== '') {
          this.$refs.subForm.validateField('surePass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.subForm.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      setState: false,
      subDialog: false,
      subForm: {
        oldPass: '',
        newPass: '',
        surePass: '',
      },
      rules: {
        oldPass: {required: true, message: '请输入旧密码', trigger: 'blur'},
        newPass: [{required: true, validator: validatePass, trigger: 'blur'}],
        surePass: [{required: true, validator: validatePass2, trigger: 'blur'}],
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    orgList() {
      return this.$store.state.orgList
    },
    orgName() {
      return this.$store.state.orgName
    }
  },
  created() {
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureEdit()
        }
      });
    },
    async sureEdit() {

      let _state = await selfUpdatePassword(
          this.$md5(this.subForm.oldPass),
          this.$md5(this.subForm.newPass),
      )
      if (_state.status === 200) {
        this.$message.success(_state.info)
        this.subDialog = false
        this.$refs['subForm'].resetFields();
        this.$refs['subForm'].clearValidate();
      } else {
        this.$message.error(_state.info)
      }


    },
    setPass() {
      this.subDialog = true
    },
    tabOrg() {
      this.$router.replace('/roleList')
    },
    signOut() {
      this.$confirm('您确定要退出吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$store.state.user = null
        window.localStorage.clear()
        this.$router.replace('/login')
      })

    }
  },
}
</script>

<style scoped lang="scss">
.header-wrapper {
  width: 100%;
  height: 75px;
  background: #368ad1;

  .logo-box {
    padding: 0 30px;
    line-height: 75px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .user-info {
    padding: 0 30px;
    height: 75px;
    color: #fff;
  }

}
</style>
